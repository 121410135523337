@import "~font-awesome/css/font-awesome.min.css";

.App {
  font-family: "Fira Mono", monospace;
  background-color: black;
  color: lime;
  background-color: black;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  white-space: pre-wrap;
  text-shadow:
    0 0 5px white,
    0 0 10px red,
    0 0 20px blue;
}

.audio-control-button {
  color: limegreen;
  cursor: pointer;
  font-size: 24px;
}

.email {
  color: white;
  position: fixed;
  z-index: 999;
  bottom: 0;
  margin: 16px;
  font-size: 12px;
  margin-right: 50px;
}

.email-nav {
  color: white;
  font-size: 12px;
  margin-right: 50px;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  position: relative;
}

/* Separate container for icons, positioned in the top right corner */
.icon-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
  display: flex; /* Allow icons to be displayed horizontally */
}

/* Set display to inline-block for the icons */
.nav-icon,
.audio-control-button {
  display: block;
}

/* Adjust spacing between icons */
.nav-icon {
  cursor: pointer;
  margin-right: 10px; /* Add margin to separate icons */
}

.nav-menu {
  list-style-type: none;
  padding: 0;
  background-color: #000;
  border: 2px solid #4caf50;
  max-width: 300px;
  border-radius: 5px;
  position: fixed;
  top: 40px;
  right: 0;
  display: none;
  z-index: 998;
}

.nav-menu.open {
  display: block;
}

.audio-control-button {
  color: limegreen;
  cursor: pointer;
  margin-right: 20px;
  font-size: 24px;
}

.nav-menu li {
  padding: 10px;
  cursor: pointer;
}

.nav-menu li a {
  color: #4caf50;
  text-decoration: none;
  display: block;
  padding: 5px;
}

.nav-menu li a:hover {
  background-color: #4caf50;
  color: #000;
}

.flashimg {
  height: 100%;
  z-index: 1;
  position: fixed;
}
